import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, List, useTheme } from '@mui/material';
import { useQuery } from '@apollo/client';

import { SEARCH } from '../queries/search';
import { Loading } from './Loading';
import { CanonicalSuggestion } from '../views/share/CanonicalSuggestion';
import { canonicalLink } from '../utils/routes';


export function TitleAutocomplete({ plusTitle, setAutocompleteWord, ...rest }) {

  const theme = useTheme();

  const touchMove = useRef(null);

  const { data, loading, error } = useQuery(SEARCH, {
    skip: !plusTitle,
    variables: {
      input: { q: plusTitle.slice(1), searchType: 'canonical' }
    }
  });

  // in order to use old data during loading
  const [latestSuggestions, setLatestSuggestions] = useState(null);

  useEffect(() => {
    if(!plusTitle) {
      setLatestSuggestions(null);
    } else if(data?.search?.hits) {
      setLatestSuggestions(data?.search?.hits);
    }
  }, [plusTitle, data]);

  if(error) {
    console.error(error);
    return null;
  }

  if(!loading && !latestSuggestions?.length) {
    return null;
  }

  return (
    <Box sx={{ position: 'absolute', height: 'fit-content', width: '100%',  maxWidth: 'sm', overflow: 'scroll', backgroundColor: 'background.paper', zIndex: 1, boxShadow: theme.shadows[10], ...rest.sx }}>
      {loading &&
        <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
          <Loading />
        </Box>
      }

      <List dense sx={{ mx: 2 }}>
        {latestSuggestions?.map(hit => {
            return (
              <CanonicalSuggestion
                key={hit.canonicalContent._id}
                suggestion={hit}
                onTouchStart={() => touchMove.current = false}
                onTouchMove={() => touchMove.current = true}
                onTouchEnd={ev => { ev.preventDefault(); ev.stopPropagation(); !touchMove.current && setAutocompleteWord(`https://app.icecream.club${canonicalLink(hit.canonicalContent._id)}`); }}
                onClick={ ev => {
                  if (touchMove.current === null) {
                    ev.preventDefault();
                    ev.stopPropagation();
                    setAutocompleteWord(`https://app.icecream.club${canonicalLink(hit.canonicalContent._id)}`);
                  }
                }}
              />
            );
          }
        )}
      </List>
    </Box>
  );
}

TitleAutocomplete.propTypes = {
  plusTitle: PropTypes.string,
  setAutocompleteWord: PropTypes.func.isRequired,
};

