import { FC, SyntheticEvent } from 'react';
import { IconButton, Typography, useTheme } from '@mui/material';
import { Heart } from 'react-feather';

import { Comment, Contact } from '../../generated/graphql';
import { useSession } from '../../hooks/auth';
import { useAnalyticsQueued } from '../../hooks/delicious-analytics';
import { useLoginPush } from '../../hooks/login-push';
import { AddCommentReactionShare, useAddCommentLike } from '../../hooks/reaction';


export type CommentLikeProps = {
  share: AddCommentReactionShare,
  comment: Pick<Comment, '_id'> & {
    reactions: Array<{
      reacted: Array<Pick<Contact, '_id'>>
    }>
  }
}

export const CommentLike: FC<CommentLikeProps> = function Comment({ share, comment }) {

  const theme = useTheme();
  const { user } = useSession();
  const { track } = useAnalyticsQueued();
  const { setLoginHint } = useLoginPush();
  const addCommentLike = useAddCommentLike();

  const handleClick = (selectedBefore: boolean) => (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if(!share || !comment._id) {
      console.error(`CommentLike.handleClick: shareId or commentId is missing`);
    } else if(user) {
      addCommentLike(share, comment._id, !selectedBefore);
      track('reaction', { category: 'comment', selected: selectedBefore, reacted: selectedBefore ? 1 : 0 });
    } else {
      track('click_like_post', { category: 'not-logged-in' });
      setLoginHint('You need to be logged in to like this.');
    }
  }

  const reactions = comment.reactions || [];
  const uniqueUsersReacted = new Set<string>();
  reactions.map(({ reacted }) => reacted.map(contact => uniqueUsersReacted.add(contact._id)));
  const userHasReacted = uniqueUsersReacted.has(user?._id);

  return (
    <IconButton
      title={userHasReacted ? 'Liked' : 'Like'}
      size="small"
      sx={{
        p: '4px',
        lineHeight: 0,
        alignItems: 'center',
        display: 'flex',
        alignSelf: 'end',
      }}
      onClick={handleClick(userHasReacted)}
    >
      <Heart size={18} color={userHasReacted ? theme.palette.primary.main : theme.palette.grey['400']} fill={userHasReacted ? theme.palette.primary.main : 'none'} style={{ marginBottom: 1 }} />
      {reactions.length > 0 && reactions[0].reacted.length > 0 &&
        <Typography variant='body2' sx={{ ml: 0.5 }}>
          {reactions[0].reacted.length}
        </Typography>
      }
    </IconButton>
  )
}
