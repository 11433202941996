import { FC } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { alpha, Typography, useTheme } from '@mui/material';


export const Searching: FC<{ show: boolean }> = function Searching({ show }) {

  const theme = useTheme();

  return (
    <AnimatePresence>
      {show &&
        <motion.div
          key="searching"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          style={{
            display: 'flex',
            position: 'absolute',
            inset: '0',
            backgroundColor: alpha(theme.palette.background.paper, 0.5), // 'rgba(255, 255, 255, 0.5)',
            overflow: 'hidden',
            backdropFilter: 'blur(3px)',
            justifyContent: "center",
            zIndex: 10,
            textShadow: '#fff 0 0 3px',
            minHeight: '100vh',
          }}
        >
          <Typography variant='h3' sx={{ mt: 8 }}>
            Searching...
          </Typography>
        </motion.div>
      }
    </AnimatePresence>
  );
}
