import { FC, useEffect, useRef, useState } from 'react';
import { Box, List, ListItem, ListItemText, SxProps, useTheme } from '@mui/material';

import { SearchContactQuery, useSearchContactQuery } from '../generated/graphql';
import { Avatar } from './Avatar';
import { Loading } from './Loading';


type MentionAutocompleteProps = {
  atName: string,
  setAutocompleteWord: (word: string) => void,
  sx?: SxProps,
}


export const MentionAutocomplete: FC<MentionAutocompleteProps> = function MentionAutocomplete({ atName, setAutocompleteWord, ...rest }) {

  const theme = useTheme();

  const touchMove = useRef<boolean | null>(null);

  const { data, loading, error } = useSearchContactQuery({
    skip: !atName,
    variables: {
      input: { query: atName.slice(1) }
    }
  });

  // in order to use old data during loading
  const [latestSuggestions, setLatestSuggestions] = useState<SearchContactQuery['searchContact'] | null>(null);

  useEffect(() => {
    if(!atName) {
      setLatestSuggestions(null);
    } else if(data?.searchContact) {
      setLatestSuggestions(data?.searchContact);
    }
  }, [atName, data]);

  if(error) {
    console.error(error);
    return null;
  }

  if(!loading && !latestSuggestions?.length) {
    return null;
  }

  if(latestSuggestions && latestSuggestions.length === 1 && atName.slice(1) === latestSuggestions[0].username) {
    // don't show suggestions if user already has perfect match
    return null;
  }

  return (
    <Box sx={{
      position: 'absolute',
      height: 'fit-content',
      width: '100%',
      maxWidth: 'sm',
      overflow: 'scroll',
      backgroundColor: 'background.paper',
      zIndex: 3,
      boxShadow: theme.shadows[10],
      ...rest.sx
    }}>
      {loading &&
        <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
          <Loading />
        </Box>
      }
      <List dense>
        {latestSuggestions?.map(contact =>
          <ListItem
            key={contact._id}
            disableGutters
            // use touch events to prevent soft keyboard from closing
            onTouchStart={() => touchMove.current = false}
            onTouchMove={() => touchMove.current = true}
            onTouchEnd={ev => {
              ev.preventDefault();
              ev.stopPropagation();
              !touchMove.current && setAutocompleteWord('@'+contact.username);
            }}
            // support click on non touch devices
            onClick={ev => {
              if (touchMove.current === null) {
                ev.preventDefault();
                ev.stopPropagation();
                setAutocompleteWord('@'+contact.username);
              }
            }}
          >
            <Avatar
              sx={{ mr: 1, ml: 2 }}
              size='medium'
              contact={contact}
            />
            <ListItemText secondary={contact.name} primary={'@'+contact.username} sx={{ m: 0 }} />

          </ListItem>
        )}
      </List>
    </Box>
  );
}
