import { useEffect, useCallback, useRef } from "react";
import { Button, Alert, Typography, Zoom } from '@mui/material';
import { useSnackbar as useNotistack, SnackbarContent } from 'notistack';
import { useHistory } from "react-router-dom";

import { CheckCircleFilled, AddCircleFilled } from "../components/icons";
import { absolutize } from "../utils/url";
import { canonicalLink } from "../utils/routes";


export function useSnackbar(share, canonicalContent) {

  const { enqueueSnackbar, closeSnackbar } = useNotistack();

  const history = useHistory();
  const mounted = useRef(false);

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (mounted.current) {
        closeSnackbar();
      } else {
        mounted.current = true;
      }
    });

    return () => {
      closeSnackbar();
      unlisten();
    };
  }, [ history, closeSnackbar ]);

  const gotoShareForm = () => {
    const link = share ? share.message.link : absolutize(canonicalLink(canonicalContent?._id));
    history.push(`/share?share-url=${encodeURIComponent(link)}&hidden=link_lists`);
  };

  const show = useCallback((text, actionText, action, icon) => {
    enqueueSnackbar(text, {
      variant: 'info',
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
      TransitionComponent: Zoom,
      content: (key, message) => (
        <SnackbarContent key={key}>
          <Alert
            color="secondary"
            severity={'info'}
            icon={icon}
            sx={{ width: '100%' }}
            action={
              <Button onClick={() => {
                action();
                closeSnackbar();
              }}>
                {actionText}
              </Button>
            }
          >
            <Typography variant="body1">{message}</Typography>
          </Alert>
        </SnackbarContent>
      )
    });
  }, [ enqueueSnackbar, closeSnackbar ]);

  return {
    watchedSnackbar: () => show('Marked as seen', 'Share', gotoShareForm, <CheckCircleFilled />),
    watchlistSnackbar: (cb) => show('Added', 'Add to collection', cb, <AddCircleFilled />),
    closeSnackbar
  };
}
