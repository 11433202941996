import { FC, SyntheticEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, ListItem, Typography, Divider, useTheme } from '@mui/material';
import { ChevronRight } from 'react-feather';

import { CanonicalContent, Contact, Context, Message, Rating, Share } from '@/generated/graphql';
import { useContact } from '@/hooks/use-contact';
import { useSession } from '@/hooks/auth';
import { canonicalLink } from '@/utils/routes';
import { domain, parseInternal } from '@/utils/url';
import { createContactType } from '@/utils/json-ld';
import { Image } from '@/components/Image';
import { FallbackImage } from '@/components/FallbackImage';
import { Avatar } from '@/components/Avatar';
import { Dialog, DialogProps } from '@/components/unfurl/Dialog';
import { ItemState } from '@/components/ItemState';
import { Helmet } from '@/components/Helmet';


export interface ShareItemProps {
  share: Pick<Share, '_id' | 'unfurl'> & {
    canonicalContent?: Pick<CanonicalContent, '_id'> | null,
    context?: Pick<Context, 'type'> | null,
    message: Pick<Message, 'link' | 'text' | 'language'> | null,
    sender?: Pick<Contact, '_id'> | null,
    rating?: Pick<Rating, 'rating'> | null,
  } & DialogProps['share'],
  listCreatedBy?: Pick<Contact, '_id'>,
  isInWatchlist: boolean,
  isWatched: number,
}

export const ShareItem: FC<ShareItemProps> = function ShareItem({ share, listCreatedBy, isInWatchlist, isWatched }) {

  const theme = useTheme();
  const history = useHistory();
  const { user } = useSession();

  const [ dialogOpen, setDialogOpen ] = useState(false);

  const { data } = useContact(share?.sender?._id);

  const image = share.unfurl.images?.[0];

  const listCreatedByMe = listCreatedBy?._id === user?._id;

  const href = canonicalLink(share.canonicalContent?._id, share._id);

  const gotoUser = (ev: SyntheticEvent) => {
    if (data?.contact?.username) {
      ev.preventDefault();
      ev.stopPropagation();
      history.push(`/@${data?.contact?.username}`)
    } else {
      gotoShare(ev);
    }
  };

  const gotoShare = (ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    history.push(href);
  };

  const link = share.message?.link ? parseInternal(share.message.link) : null;
  const linkDomain = share.message?.link ? domain(share.message.link) : '';

  const jsonLd = data?.contact?.username && createContactType(data?.contact);

  return (
    <ListItem
      component="a"
      href={href}
      sx={{ backgroundColor: 'background.paper', flexDirection: 'column', alignItems: 'flex-start', px: 2, pt: 1, pb: 0 }}
      onClick={gotoShare}
    >
      <Helmet>
        <script type="application/ld+json">{jsonLd && JSON.stringify(jsonLd)}</script>
      </Helmet>

      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
        <Box sx={{ width: 56, height: 56, borderRadius: '4px', overflow: 'hidden', flex: '0 0 auto' }}>
          {image &&
            <Image constraints='128x128_c' {...image} width={128} height={128} loading="lazy" />
          }
          {!image &&
            <FallbackImage color={theme.palette.primary.light} scale={2} style={{ width: '100%', height: '100%' }} />
          }
        </Box>

        <Box sx={{ flex: '1 1 auto', overflow: 'hidden', ml: 1.5 }}>

          <Box sx={{ display: 'flex', alignItems: 'center', color: isWatched ? 'text.secondary' : 'text.primary', mb: 0.5 }}>
            <ItemState listCreatedBySelf={listCreatedByMe} isInWatchlist={isInWatchlist} watched={isWatched} rating={share.rating} />
            <Typography variant="body1" sx={{ color: isWatched ? 'text.secondary' : 'text.primary', fontStyle: share.unfurl.title ? 'normal' : 'italic' }} noWrap>
              {share.unfurl.title || 'Title missing'}
            </Typography>
          </Box>

          <Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>
            {!link || link.isInternal ? share.unfurl?.description : linkDomain}
          </Typography>

        </Box>

        <Box sx={{ display: 'flex', ml: 2, color: 'text.primary' }}>
          <ChevronRight />
        </Box>

      </Box>

      {share.context && share.context.type !== 'self' && share.message?.text && data?.contact &&
        <Box sx={{ display: "flex", alignItems: "center", mt: 0.25, maxWidth: '100%', gap: '4px', backgroundColor: 'grey.100', borderRadius: '4px', p: 0.5 }}>
          <Avatar onClick={gotoUser} contact={data.contact} size='xsmall' />
          <Typography color="text.secondary" onClick={gotoUser}>
            {data?.contact?.username}
          </Typography>
          <Typography onClick={gotoShare} noWrap color="text.primary" lang={share.message.language}>
            {share.message.text}
          </Typography>
        </Box>
      }

      <Divider sx={{ borderColor: theme.palette.grey['200'], width: '100%', mt: 1 }}/>

      {dialogOpen &&
        <Dialog share={share} canonicalContent={undefined} open={dialogOpen} setOpen={setDialogOpen} />
      }

    </ListItem>
  );
}
