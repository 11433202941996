import { FC } from "react";
import { Box, NativeSelect, Stack, useTheme } from "@mui/material";
import { BooleanParam, useQueryParam } from "use-query-params";

import { Provider, useProvidersByCountryQuery } from "../../generated/graphql";
import { useSession } from "../../hooks/auth";
import { useNavigationActions } from "../../hooks/history";
import { ProviderLogo } from "../../components/ProviderLogo";
import { FilterButton } from "../../components/FilterButton";
import { Imdb } from "../../components/icons/Imdb";
import { ProvidersSheet } from "../../components/ProvidersSheet";


type FiltersProps = {
  onlyProviders: boolean,
  setOnlyProviders: (onlyProviders: boolean) => void,
  showWatched?: boolean,
  setShowWatched: (showWatched: boolean) => void,
  typeIndex: number,
  setUrlTypes: (types: string) => void,
  minImdbRating?: number,
  setMinImdbRating: (minImdbRating: number) => void,
  types: string[],
  nextType: string,
};


export const Filters: FC<FiltersProps> = function Filters({ onlyProviders, setOnlyProviders, showWatched, setShowWatched, typeIndex, setUrlTypes, minImdbRating, setMinImdbRating, types, nextType }) {

  const { user } = useSession();
  const theme = useTheme();
  const { closeSheet } = useNavigationActions();

  const sheetQueryParam = "providers-sheet";
  const [providersOpen, setProvidersOpen] = useQueryParam(sheetQueryParam, BooleanParam);

  const providersResult = useProvidersByCountryQuery({ variables: { country: user?.country || 'SE' }, ssr: false });

  const userProviders: Pick<Provider, '_id'>[] = user?.providers || [];

  const handleClick = () => {
    if(userProviders.length === 0) {
      setProvidersOpen(true);
    } else {
      setOnlyProviders(!onlyProviders);
    }
  };

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 1, my: 2 }}>
      <FilterButton
        active={onlyProviders}
        onClick={handleClick}
      >
        {onlyProviders ? 'Only streaming' : 'Showing all'}

        <Stack
          direction='row'
          spacing={0.5}
          sx={{
            filter: onlyProviders ? '' : 'grayscale(100%) opacity(50%)',
          }}
          onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
            setProvidersOpen(true);
          }}
        >
          {userProviders.map(
            (usersProvider: { _id: string }) => {
              const provider = providersResult.data?.providers?.find(p => p._id === usersProvider._id);
              if(!provider || !provider.logo) {
                return null;
              }
              return (
                <ProviderLogo
                  key={provider._id}
                  name={provider.name}
                  logo={provider.logo}
                  size='tiny'
                  sx={{
                    flex: '0 0 auto',
                    borderRadius: '50%',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'white',
                    boxSizing: 'content-box',
                  }}
                />
              );
            }
          )}
        </Stack>

      </FilterButton>
      <ProvidersSheet
        open={Boolean(providersOpen)}
        onClose={(ev) => {
          ev?.stopPropagation();
          ev?.preventDefault();
          closeSheet(sheetQueryParam);
        }}
        country={user?.country || 'SE'}
      />

      <FilterButton
        active={!showWatched}
        onClick={() => setShowWatched(!showWatched)}
      >
        {showWatched ? 'Showing Seen' : 'Hiding Seen'}
      </FilterButton>

      <FilterButton
        active={typeIndex > 0}
        onClick={() => setUrlTypes(nextType)}
      >
        Showing {types.map(t => ({movie: 'movies', tvshow: 'TV shows'}[t])).join(' and ')}
      </FilterButton>

      <FilterButton
        active={Boolean(minImdbRating)}
        onClick={(ev) => {
          const el = ev.target as HTMLElement;
          el.querySelector('select')?.focus();
        }}
        sx={{ flexDirection: 'row' }}
      >
        <Imdb width={48} />&nbsp;&gt;&nbsp;{new Intl.NumberFormat('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(minImdbRating || 0)}
        <NativeSelect
          value={minImdbRating || 0}
          onChange={ev => setMinImdbRating(Number(ev.target.value))}
          IconComponent={() => null}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            color: 'transparent',
            backgroundColor: 'transparent',
            '&:hover': { backgroundColor: 'transparent' },
            '&.MuiInput-root:after': { display: 'none' },
            '&.MuiInput-root:before': { display: 'none' },
            '& .MuiInput-input:focus': { backgroundColor: 'transparent' },
            '& option': { color: theme.palette.text.primary },
          }}
        >
          <option value={0}>All</option>
          <option value={0.5}>0.5</option>
          <option value={1.0}>1.0</option>
          <option value={1.5}>1.5</option>
          <option value={2.0}>2.0</option>
          <option value={2.5}>2.5</option>
          <option value={3.0}>3.0</option>
          <option value={3.5}>3.5</option>
          <option value={4.0}>4.0</option>
          <option value={4.5}>4.5</option>
          <option value={5.0}>5.0</option>
          <option value={5.5}>5.5</option>
          <option value={6.0}>6.0</option>
          <option value={6.5}>6.5</option>
          <option value={7.0}>7.0</option>
          <option value={7.5}>7.5</option>
          <option value={8.0}>8.0</option>
          <option value={8.5}>8.5</option>
          <option value={9.0}>9.0</option>
          <option value={9.5}>9.5</option>
        </NativeSelect>
      </FilterButton>
    </Box>
  );
}
