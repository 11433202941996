import { FC, MouseEvent } from 'react';
import { Box, Card, Typography, useTheme, SxProps } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Bookmark, Lock } from 'react-feather';

import { Contact, List } from '../generated/graphql';
import { useContact } from '../hooks/use-contact';
import { useSession } from '../hooks/auth';
import { Image } from './Image';
import { Avatar } from './Avatar';


export type ListCardProps = {
  list: Pick<List, '_id' | 'name' | 'images' | 'isPublic' | 'itemCount'> & { createdBy?: Pick<Contact, '_id' | 'username' | 'avatar'> | null },
  showOwner?: boolean,
  sx?: SxProps,
}

export const ListCard: FC<ListCardProps> = function ListCard({ list, showOwner=true, sx={}, ...rest }) {

  const history = useHistory();
  const theme = useTheme();
  const { user } = useSession();

  const { data } = useContact(list.createdBy?._id);
  const createdBy = data?.contact;

  // unique images in grid
  const images = [...new Map(list.images.map(image => [image.hash, image])).values()];

  const href = `/@${createdBy?.username}/collections/${list._id}`;

  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(href);
  };

  return (
    <Card
      component='a'
      href={href}
      onClick={handleClick}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 150,
        maxWidth: 200,
        overflow: 'hidden',
        backgroundColor: 'grey.50',
        borderRadius: 1,
        position: 'relative',
        flexBasis: 0,
        ...sx,
      }}
      {...rest}
    >
      {images.length >= 4 &&
        <Box sx={{ display: 'grid', gridTemplate: '1fr 1fr / 1fr 1fr' }}>
          {images.slice(0, 4).map(image => (
            <Image key={image.hash} {...image} width={128} height={128} constraints='128x128_c' />
          ))}
        </Box>
      }
      {images.length < 4 && images.length > 0 &&
        <Box>
          <Image {...images[0]} width={256} height={256} constraints='256x256_c' />
        </Box>
      }
      {images.length === 0 &&
        <Box sx={{
          aspectRatio: '1/1',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'grey.100',
        }}>
          <Bookmark style={{
            display: 'block',
            width: 40,
            height: 40,
            borderRadius: '50%',
            padding: 6,
            borderWidth: 4,
            borderStyle: 'solid',
            borderColor: theme.palette.grey[400],
            backgroundColor: theme.palette.grey[600],
            stroke: theme.palette.background.paper,
            fill: theme.palette.background.paper,
          }} />
        </Box>
      }
      {createdBy && createdBy._id !== user?._id && showOwner &&
        <Box sx={{
          position: 'absolute',
          top: 8,
          left: 8,
          display: 'flex',
          borderRadius: '999px',
          backdropFilter: 'blur(3px) contrast(0.7)',
          p: 0.5,
          pl: '6px',
          backgroundColor: 'rgba(0, 0, 0, 0.15)',
          width: 'fit-content',
          zIndex: 1,
          alignItems: 'center'
        }}>
          <Avatar contact={createdBy} size='xsmall' />
          <Typography variant='body2' sx={{ color: 'white', mx: '6px', fontSize: 12 }}>
            {createdBy.username}
          </Typography>
        </Box>
      }
      <Box sx={{
        px: 1,
        py: 0.5,
        borderColor: 'grey.200',
        borderWidth: 1,
        borderStyle: 'solid',
        borderTopWidth: 0,
      }}>
        <Typography variant="body1" noWrap>{list.name}</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {!list.isPublic && <Lock style={{ strokeWidth: 2, width: 11, height: 11, marginRight: 3 }} />}
          {list.itemCount} items
        </Typography>
      </Box>
    </Card>
  );
}
