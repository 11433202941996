import { FC, useRef, useState } from 'react';
import { Box, SxProps, Typography, alpha, useTheme } from '@mui/material';

import { Share } from '../generated/graphql';
import { useBrowserLayoutEffect } from '../hooks';
import { DecoratedText, DecoratedTextProps } from './DecoratedText';


export type ShareTextProps = {
  share: {
    message?: Pick<Share['message'], 'text' | 'language'> | null,
    textLinks?: DecoratedTextProps['links'],
    mentions?: DecoratedTextProps['mentions'],
  },
  link?: boolean,
  collapse?: boolean,
  color?: string,
  maxHeight?: number,
  toggle?: boolean,
  sx?: SxProps,
};

export const ShareText: FC<ShareTextProps> = function ShareText({ share, link = true, collapse = true, color, maxHeight=150, toggle=true, sx }) {

  const theme = useTheme();
  color = color || theme.palette.grey['100'];

  const [isTextCollapsed, setIsTextCollapsed] = useState(true);
  const textOverflowRef = useRef<HTMLElement | null>(null);
  const [isTextOverflow, setIsTextOverflow] = useState(false);

  useBrowserLayoutEffect(() => {
    if(collapse && textOverflowRef.current) {
      if(textOverflowRef.current.offsetHeight < textOverflowRef.current.scrollHeight && !isTextOverflow) {
        setIsTextOverflow(true);
      }
    }
  }, [collapse, isTextOverflow]);

  return (
    <>
      {share?.message?.text &&
        <Box sx={{ position: 'relative', ...sx }} onClick={() => toggle && setIsTextCollapsed(!isTextCollapsed)}>
          <Box
            ref={textOverflowRef}
            sx={{
              overflow: 'hidden',
              maxHeight: collapse && isTextCollapsed ? `${maxHeight}px` : '',
              position: 'relative',
              overflowWrap: 'break-word',
              '&:after': (isTextOverflow && isTextCollapsed) ? {
                content: '""',
                width: '100%',
                height: `${0.5 * maxHeight}px`,
                position: 'absolute',
                left: 0,
                bottom: 0,
                background: `linear-gradient(${alpha(color, 0.001)} 0px, ${color})`,
              } : {}
            }}
            lang={share.message.language}
          >
            <DecoratedText text={share?.message?.text} links={share?.textLinks} mentions={share?.mentions} link={link} />
          </Box>
          {(toggle && isTextCollapsed && isTextOverflow) &&
            <Box sx={{ display: 'flex', justifyContent: 'center', position: 'absolute', bottom: '8px', width: '100%' }}>
              <Typography
                variant="body2"
                color={theme.palette.grey[600]}
                sx={{
                  backgroundColor: theme.palette.grey['50'],
                  borderRadius: '12px',
                  border: `1px solid ${theme.palette.grey['300']}`,
                  padding: '0px 8px',
                  boxShadow: '0px 1px 1px 0px rgb(0 0 0 / 10%)'
                  }}>
                More
              </Typography>
            </Box>
          }
        </Box>
      }
    </>
  )

}
