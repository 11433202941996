import { createElement, memo, useCallback, useMemo, useRef, useState, useId, ComponentType, FC, ReactNode, RefObject } from 'react';
import { Typography, Container, Box, useTheme, IconButton } from '@mui/material';
import { ChevronLeft } from 'react-feather';

import { PullToRefresh } from '../PullToRefresh';
import { BackButton } from '../BackButton';


export interface LayoutContentProps {
  scrollContainerRef: RefObject<HTMLDivElement>,
  scrollContentRef: RefObject<HTMLDivElement>,
  addRefreshListener: (f: () => void) => void,
  isVisible: boolean,
}

export type LayoutContent = ComponentType<LayoutContentProps>;

export interface StaticLayoutProps {
  title: ReactNode,
  content: LayoutContent,
  contentProps: object,
  onRefresh?: () => void,
  headerColor?: string,
  headerContent?: ReactNode,
  headerContentHeight?: number,
  backButton?: boolean | ReactNode,
  action?: ReactNode,
}

export const StaticLayout: FC<StaticLayoutProps> = memo(function StaticLayout({ title, content, contentProps, onRefresh, headerColor, headerContent, headerContentHeight=0, backButton=false, action }: StaticLayoutProps) {
  const theme = useTheme();

  // react-lazyload doesn't work without a selector for scroll container, so we generate a unique id
  const uuid = useId();

  const scrollContentRef = useRef<HTMLDivElement | null>(null);

  const [scrollContainerRef, _setScrollContainerRef] = useState<{ current: HTMLDivElement | null }>({ current: null });

  const setScrollContainerRef = useCallback((el: HTMLDivElement) => {
    _setScrollContainerRef({ current: el });
  }, []);


  const [refreshListeners, setRefreshListeners] = useState<(() => void)[]>([]);

  const onRefreshCallback = useCallback(() => {
    return Promise.all([
      onRefresh && onRefresh(),
      ...refreshListeners.map(f => f())
    ]);
  }, [refreshListeners, onRefresh]);

  const addRefreshListener = useCallback((listener: () => void) => {
    setRefreshListeners((prevState) => [...prevState, listener]);
  }, [setRefreshListeners]);

  if(!headerColor) {
    headerColor = theme.palette.background.paper;
  }

  let backButtonComponent = null;
  if(backButton === true) {
    backButtonComponent = (
      <BackButton component={IconButton} sx={{ p: 1, lineHeight: 0 }}>
        <ChevronLeft style={{ strokeWidth: 3, strokeLinecap: 'square', height: '30px', width: '30px', color: theme.palette.text.primary }} />
      </BackButton>
    );
  } else if(backButton) {
    backButtonComponent = backButton;
  }

  const isVisible = 'isVisible' in contentProps ? contentProps.isVisible as boolean : true;

  const component = useMemo(() => {
    // eslint-disable-next-line react-compiler/react-compiler
    return createElement<LayoutContentProps>(content, { ...contentProps, scrollContainerRef, scrollContentRef, addRefreshListener, isVisible }, null)
  }, [ content, addRefreshListener, contentProps, scrollContainerRef, isVisible ])

  const headerHeight = 56;

  return (
    <Container maxWidth="sm" disableGutters={true} sx={{ height: '100%', backgroundColor: theme.palette.background.paper }}>
      <Box style={{ height: '100%', position: 'relative' }}>
        <Box
          sx={{
            position: 'relative',
            zIndex: 2,
            width: '100%',
            height: headerHeight + headerContentHeight,
            borderBottom: theme.other.feedBorder,
            backgroundColor: headerColor,
          }}
        >

          <Box sx={{
            height: headerHeight,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <Box sx={{ height: '100%', minWidth: 48, flex: '0 0 auto', display: 'flex' }}>
              {backButtonComponent}
            </Box>

            <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
              <Box component='span' sx={{ flex: '1', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                {title || <>&nbsp;</>}
              </Box>
            </Typography>

            <Box sx={{ height: '100%', width: 48, flex: '0 0 auto', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
              {action}
            </Box>
          </Box>
          {headerContent &&
            <Box sx={{ height: headerContentHeight }}>
              {headerContent}
            </Box>
          }
        </Box>

        <Box sx={{ height: '100%', position: 'absolute', width: '100%', top: 0, pt: `${headerHeight + headerContentHeight}px` }}>
          <PullToRefresh onRefresh={onRefreshCallback} isPullable={!!onRefresh}>
            <div style={{
              position: 'absolute',
              top: 0,
              height: '100%',
              overflow: 'auto',
              width: '100%',
            }}>
              <div ref={setScrollContainerRef} id={uuid} style={{ overflow: 'scroll', height: '100%' }} className='scrollContainer'>
                <div ref={scrollContentRef} style={{ minHeight: '100%', paddingBottom: 96 }}>

                  {component}

                </div>
              </div>
            </div>
          </PullToRefresh>
        </Box>

      </Box>
    </Container>
  );
});
