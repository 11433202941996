import { FC, ReactNode } from 'react';
import { Box, Typography, useTheme, SxProps, TypographyProps } from '@mui/material';
import { GroupColor } from '../mui';


export type DotChipProps = {
  color: string,
  borderColor?: string,
  size?: 'small' | 'medium',
  name: string,
  icon?: ReactNode,
  variant?: TypographyProps['variant'],
  onClick?: () => void,
  sx?: SxProps & {
    dot?: SxProps,
    typography?: SxProps
  },
}


export const DotChip: FC<DotChipProps> = function DotChip({ color, borderColor, size='medium', name, icon, variant, onClick=()=>null, sx={} }) {

  const theme = useTheme();
  const backgroundColor = color in theme.palette.groups ? theme.palette.groups[color as GroupColor].main : color;
  borderColor = color in theme.palette.groups ? theme.palette.groups[color as GroupColor].light : borderColor;

  if(!variant) {
    variant = size === 'small' ? 'body2' : 'h3';
  }

  const { dot: dotSx, typography: typographySx, ...restSx } = sx;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...restSx }} onClick={onClick}>
      <Box sx={{
        backgroundColor,
        width: size === 'small' ? 20 : 24,
        minWidth: size === 'small' ? 20 : 24,
        height: size === 'small' ? 20 : 24,
        mr: size === 'small' ? 0.5 : 1,
        borderRadius: '50%',
        borderWidth: size === 'small' ? 2 : 3,
        borderStyle: 'solid',
        borderColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...dotSx,
      }}>
        {icon || ''}
      </Box>
      <Typography noWrap variant={variant} sx={typographySx}>
        {name}
      </Typography>
    </Box>
  );
}
