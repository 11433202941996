import { FC, SyntheticEvent, useState } from "react";
import { Box, SxProps, useTheme } from "@mui/material";

import { CanonicalContent, Rating, Share } from "@/generated/graphql";
import { useAnalyticsQueued } from "@/hooks/delicious-analytics";
import { useIsWatched, useToggleWatched } from "@/hooks/watch-state";
import { RatingPopover } from "@/components/RatingPopover";
import { RatingIcon } from "@/components/icons/RatingIcon";
import { Watched } from "@/components/icons/Watched";


export type WatchedIconButtonProps = {
  share?: Pick<Share, '_id'> & {
    rating?: Pick<Rating, 'rating'> | null,
  },
  canonicalContent?: Pick<CanonicalContent, '_id'> & {
    rating?: Pick<Rating, 'rating'> | null,
  } | null,
  sx?: SxProps,
}


export const WatchedIconButton: FC<WatchedIconButtonProps> = function WatchedIconButton({ share, canonicalContent, sx={} }) {

  const theme = useTheme();
  const { track } = useAnalyticsQueued();

  const isWatched = useIsWatched(share?._id, canonicalContent?._id);
  const { toggleWatched } = useToggleWatched();
  const setIsWatched = (v: boolean) => toggleWatched(canonicalContent?._id, share?._id, v);

  const [ ratingPopoverOpen, setRatingPopoverOpen ] = useState(false);
  const rating = canonicalContent?.rating || share?.rating;

  const handleClickWatched = (ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();

    track('toggle_watched_button', { category: 'explore' });
    setIsWatched(!isWatched);
    if(!rating) {
      setRatingPopoverOpen(true);
    }
  };

  return (
    <Box
      sx={{
        typography: 'body2',
        color: "text.secondary",
        display: 'flex',
        alignItems: 'center',
        height: '24px',
        ...sx,
      }}
      onClick={handleClickWatched}
    >
      <Watched isWatched={isWatched} sx={{ marginRight: '4px', width: 14, height: 14 }} unselectedColor={theme.palette.grey[500]} />
      {rating &&
        <RatingIcon value={rating.rating} highlight width='14px' style={{ marginRight: '4px' }} />
      }
      Seen
      <RatingPopover canonicalContentId={canonicalContent?._id} shareId={share?._id} isWatched={isWatched} open={ratingPopoverOpen} onClose={() => setRatingPopoverOpen(false)} rating={rating || undefined} handleWatched={handleClickWatched} />
    </Box>
  );
}
