import { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";

import { useAnalyticsQueued } from "./delicious-analytics";
import { parseInternal } from "../utils/url";


export function useOpenLink(link: string) {

  const { track } = useAnalyticsQueued();
  const history = useHistory();

  const { url, parsedLink } = useMemo(() => {
    let url: URL;
    try {
      url = new URL(link);
    } catch (e) {
      console.error('Invalid URL, using fallback to not crash app', link);
      url = new URL('https://app.icecream.club/');
    }
    const parsedLink = parseInternal(url.href);
    return { url, parsedLink };
  }, [link]);

  const openLink = useCallback(() => {
    if (!parsedLink.isInternal) {
      track('outbound', { category: 'link', origin: 'post' });
      if (Capacitor.isNativePlatform()) {
        Browser.open({ url: link, presentationStyle: 'popover' });
      } else {
        window.open(link, '_blank');
      }
    } else {
      history.push(`${url.pathname}?${url.searchParams}`);
    }
  }, [parsedLink.isInternal, track, link, history, url.pathname, url.searchParams]);

  if(!link) {
    return { openLink: () => console.error('No link to open') };
  }

  return { openLink };
}
