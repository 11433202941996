import { FC, useState } from "react";
import { useTheme } from "@mui/material";
import { Copy } from "react-feather";

import { useSession } from "../hooks/auth";
import { useAnalyticsQueued } from "../hooks/delicious-analytics";
import { useLoginPush } from "../hooks/login-push";
import { Button } from "./Button";
import { Loading } from './Loading';
import { CopyInviteLink } from "./CopyInviteLink";


export const CopyInviteLinkButton: FC<{ link: string, origin: string, loading: boolean }> = function CopyInviteLinkButton({ link, origin, loading }) {
  const theme = useTheme();
  const { track } = useAnalyticsQueued();
  const { user } = useSession();
  const { setLoginHint } = useLoginPush();

  const [copyInviteLinkOpen, setCopyInviteLinkOpen] = useState(false);

  return (
    <>
      <Button
        variant='contained'
        size='small'
        sx={{ backgroundColor: theme.palette.grey[300], color: theme.palette.text.primary }}
        onClick={() => {
          if(!user) {
            track('click_copy_invite_link', { category: 'not-logged-in', origin });
            setLoginHint('You need to be logged in use the share function.');
            return;
          }
          if(!link) {
            console.error(`CopyInviteLinkButton: Missing link for origin ${origin}`);
            return;
          }
          track(`copy_invite_link`, { category: 'invite', origin });
          setCopyInviteLinkOpen(true);
        }}
        disabled={loading}
        data-cy="invite-copy-link"
      >
        {loading
          ? <Loading style={{ marginRight: 4, width: 16, height: 16 }} />
          : <Copy style={{ marginRight: 4, width: 16, height: 16 }} />
        }
        Copy link
      </Button>
      {copyInviteLinkOpen &&
        <CopyInviteLink onClose={() => setCopyInviteLinkOpen(false)} invitationLink={link} />
      }
    </>
  );
};
