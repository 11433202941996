import { forwardRef, SyntheticEvent } from 'react';
import { Avatar as MuiAvatar, AvatarProps as MuiAvatarProps, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { useContact } from '../hooks/use-contact';
import { Contact } from '../generated/graphql';


export type AvatarProps = MuiAvatarProps & {
  contact: Pick<Contact, '_id' | 'username' | 'avatar'> | string | undefined | null;
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  link?: boolean;
  loading?: 'eager' | 'lazy';
}


export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(function Avatar({ contact, size, link=false, sx={}, loading="lazy", ...rest }, ref) {

  const history = useHistory();
  const theme = useTheme();

  let width = '32px', height = '32px', fontSize = '18px', borderWidth = '2px';
  if (size === 'small') {
    width = '20px', height = '20px', fontSize = '12px', borderWidth = '1.5px';
  } else if (size === 'large') {
    width = '40px', height = '40px', fontSize = '20px', borderWidth = '2px';
  } else if (size === 'xlarge') {
    width = '64px', height = '64px', fontSize = '24px', borderWidth = '2px';
  } else if (size === 'xsmall') {
    width = '16px', height = '16px', fontSize = '12px', borderWidth = '1.5px';
  }

  let c = { _id: '' } as Pick<Contact, '_id' | 'username' | 'avatar'>;
  if (typeof contact === 'string') {
    c._id = contact;
  } else if (typeof contact === 'undefined' || contact === null) {
    c._id = '';
  } else {
    c = contact;
  }

  const { data, error } = useContact(c._id, { skip: !!c.avatar }); // skip lookup if we already have avatar in prop

  if(error) {
    console.error("Couldn't load contact from Avatar, ignoring error", { contact, c })
  }

  if(data?.contact) {
    c = data?.contact;
  }

  return (
    <MuiAvatar
      ref={ref}
      alt={(c.username && c.avatar?.url) ? `Avatar for ${c.username}` : ''}
      src={c.avatar?.url || ''}
      imgProps={{ loading }}
      component='span'
      sx={{
        color: theme.palette.text.primary,
        fontWeight: 500,
        backgroundColor: theme.palette.grey['300'],
        borderWidth,
        fontSize,

        width,
        height,
        borderStyle: 'none',
        '&&&': {
          borderWidth,
        },
        '&&& span.MuiAvatar-colorDefault, &&& div.MuiAvatar-colorDefault': {
          width,
          height,
        },
        '&&& svg': {
          m: '12%',
        },
        ...sx
      }}
      onClick={link
        ? (ev: SyntheticEvent) => {
          ev.preventDefault();
          ev.stopPropagation();
          history.push(c.username ? `/@${c.username}` : `/profile-id/${c._id}`);
        }
        : undefined
      }
      {...rest}
    />
  );
});
