import { ComponentType, FC, ReactNode, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { canonicalLink } from '../../utils/routes';
import { CompactUnfurl, CompactUnfurlProps } from './CompactUnfurl';


export type CompactShareUnfurlProps = {
  ContentLink?: ComponentType<{ children: ReactNode }>,
} & Required<Pick<CompactUnfurlProps, 'share'>> & Omit<CompactUnfurlProps, 'share'>;

export const CompactShareUnfurl: FC<CompactShareUnfurlProps> = function CompactShareUnfurl({ share, ContentLink=undefined, ...rest }) {

  ContentLink = useMemo(() => {
    if(!ContentLink) {
      const linkHref = canonicalLink(share?.canonicalContent?._id, share?._id);
      // eslint-disable-next-line
      return ({ children }: { children: ReactNode }) => (<RouterLink to={linkHref} style={{ maxWidth: '100%' }} onClick={ (e) => e.stopPropagation() }>{children}</RouterLink>);
    }
    return ContentLink;
  }, [ContentLink, share]);

  return (
    <ContentLink>
      <CompactUnfurl share={share} {...rest} />
    </ContentLink>
  );
}
