import { FC, Suspense, SyntheticEvent, useRef } from "react";
import { Box, NoSsr, SxProps, useTheme } from "@mui/material";
import loadable from '@loadable/component';
const ReactPlayer = loadable(() => import(/* webpackChunkName: "player" */'react-player/lazy'));
import { VolumeUp } from "@mui/icons-material";

import { useIsVisible } from "../hooks/use-is-visible";
import { useVideoPlayer } from "@/hooks/video-player";


type AutoplayingVideoProps = {
  url: string,
  sx: SxProps,
  onClick?: (ev: SyntheticEvent) => void,
}


const playerSx: SxProps = {
  '.hidden &': {
    display: 'none'
  },
  width: '100%',
  aspectRatio: '16/9',
  overflow: 'hidden',
  position: 'relative',
  transition: 'rotate 0.2s',
  '&.fullscreen': {
    width: '100vh',
    height: '100vw',
    position: 'fixed',
    top: 'calc(50% - 50vw)',
    left: 'calc(50% - 50vh)',
    backgroundColor: 'black',
    zIndex: 99999,
    transformOrigin: 'center center',
  },
  '&.fullscreen.landscape-left': {
    rotate: '90deg',
  },
  '&.fullscreen.landscape-right': {
    rotate: '-90deg',
  },
};


export const AutoplayingVideo: FC<AutoplayingVideoProps> = function AutoplayingVideo({ url, sx={}, onClick }) {
  const theme = useTheme();

  const isVisible = useIsVisible();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const playerRef = useRef<any>();
  const containerRef = useRef<HTMLDivElement>(null);

  const { player, handleClick, fullscreenOrientation, activationAnimationState } = useVideoPlayer(playerRef, containerRef, onClick);

  const fullscreenClassnames = player?.isFullscreen ? `fullscreen ${fullscreenOrientation}` : '';

  return (
    <Box sx={{ borderRadius: 0.5, overflow: 'hidden', ...sx }}>
      <style>
        {`
          @keyframes fade-in-out {
            0% { opacity: 0; }
            20% { opacity: 1; }
            40% { opacity: 1; }
            100% { opacity: 0; }
          }
        `}
      </style>
      <Box ref={containerRef} sx={playerSx} className={fullscreenClassnames}>
        <NoSsr>
          {isVisible &&
            <Suspense fallback={null}>
              <ReactPlayer
                url={url}
                playing={player?.isPlaying}
                ref={playerRef}
                width='100%'
                height='100%'
                controls={!onClick} // cannot be modified after initial render (ie on activated)
                playsinline={true}
                volume={1}
                muted={!player?.isActivated}
                loop={!player?.isActivated}
                style={{ zIndex: theme.zIndex.videoPlayer }}
                config={{ youtube: { playerVars: { vq: 'tiny', modestbranding: 1, fs: 0 }, embedOptions: { host: 'https://www.youtube-nocookie.com' } } }}
              />
            </Suspense>
          }
        </NoSsr>

        <Box sx={{ position: 'absolute', inset: 0, justifyContent: 'center', alignItems: 'center', display: (player?.isActivated && activationAnimationState === 'completed') ? 'none' : 'flex'}} onClick={handleClick}>
          <VolumeUp sx={{ height: '65%', width: 'auto', opacity: '0', color: 'primary.light', '&.showVolume': { animation: '1.2s ease-in 0s fade-in-out' } }} className={activationAnimationState === 'running' ? 'showVolume' : ''} />
        </Box>

        {/* <Box sx={{ position: 'absolute', top: 0, left: 0, width: 48, height: 48, backgroundColor: 'pink' }} onClick={(ev) => { ev.stopPropagation(); setFullscreen(!player?.isFullscreen); }}>Fullscreen</Box>
        <Box sx={{ position: 'absolute', bottom: 0, left: 0, width: 48, height: 48, backgroundColor: 'pink' }} onClick={(ev) => { ev.stopPropagation(); setActivated(!player?.isActivated); }}>Activated</Box> */}
      </Box>
    </Box>
  );
}
