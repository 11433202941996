import { FC, memo, SyntheticEvent } from 'react';
import { Box, Card, SxProps, Typography, useTheme } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';

import { CanonicalContent, Movie, Rating, Share, TvShow } from '../../generated/graphql';
import { useAnalyticsQueued } from '../../hooks/delicious-analytics';
import { useSession } from '../../hooks/auth';
import { useIsInWatchlist, useIsWatched } from '../../hooks/watch-state';
import { useLoginPush } from '../../hooks/login-push';
import { domain } from '../../utils/url';
import { FallbackImage } from '../FallbackImage';
import { Image } from '../Image';
import { TitleLink } from './TitleLink';
import { TypeYear } from '../canonical/TypeYear';
import { ItemState } from '../ItemState';


export type CompactUnfurlProps = {
  share?: Pick<Share, '_id'> & {
    canonicalContent?: Pick<CanonicalContent, '_id'> | null,
    rating?: Pick<Rating, 'rating'> | null,
    unfurl: Pick<Share['unfurl'], 'title' | 'link' | 'images'>,
    message?: Pick<Share['message'], 'image'> | null,
  },
  canonicalContent?: Pick<CanonicalContent, '_id'> & {
    rating?: Pick<Rating, 'rating'> | null,
    movie?: Pick<Movie, 'title' | 'poster'> | null,
    tvshow?: Pick<TvShow, 'title' | 'poster'> | null,
  } | null,
  sx?: SxProps,
  setDialogOpen?: (o: boolean) => void,
};


export const CompactUnfurl: FC<CompactUnfurlProps> = memo(function CompactUnfurl({ share, canonicalContent, sx={}, setDialogOpen }: CompactUnfurlProps) {

  const theme = useTheme();
  const { user } = useSession();
  const { track } = useAnalyticsQueued();
  const { setLoginHint } = useLoginPush();

  const canonicalId = canonicalContent?._id || share?.canonicalContent?._id;

  const isWatched = useIsWatched(share?._id, canonicalId);
  const isInWatchlist = useIsInWatchlist(canonicalId, share?._id);

  const rating = share?.rating || canonicalContent?.rating;

  let image = null, title, description;
  if (canonicalContent) {
    image = canonicalContent.movie?.poster || canonicalContent.tvshow?.poster;
    title = canonicalContent.movie?.title || canonicalContent.tvshow?.title;

    if (share && share.unfurl.link && !['app.icecream.club', 'imdb.com', 'm.imdb.com', 'youtube.com'].includes(domain(share.unfurl.link))) {
      description = <TitleLink title={share.unfurl.title} link={share.unfurl.link} />;
    } else {
      description = <TypeYear canonical={canonicalContent} />;
    }
  } else if (share) {
    if(share.message?.image && share.message.image.hash) {
      image = share.message.image;
    } else if(share.unfurl?.images?.[0]) {
      image = share.unfurl?.images?.[0];
    }
    title = share.unfurl.title;
    if(share.unfurl.link) {
      description = domain(share.unfurl.link);
    }
  }

  const openDialog = (ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    if(user) {
      setDialogOpen && setDialogOpen(true);
      track('bookmark_button', { category: 'bookmark', origin: 'group' });
    } else {
      track('click_bookmark_post', { category: 'not-logged-in' });
      setLoginHint('You need to be logged in to save this.');
    }
  };

  if(!share && !canonicalContent) {
    console.error('CompactUnfurl requires either a share or canonicalContent');
    return null;
  }

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 64, borderRadius: 1, border: `1px solid ${theme.palette.grey['300']}`, ...sx }}>
      <Box sx={{ display: 'flex', overflow: 'hidden' }}>
        <Box sx={{ width: 64, flex: '0 0 auto' }}>
          {image &&
            <Image {...image} constraints='128x128_c' width={128} height={128} loading='lazy' />
          }
          {!image &&
            <FallbackImage color={theme.palette.primary.light} scale={2} style={{ height: '100%' }} />
          }
        </Box>
        <Box
          sx={{
            px: 1,
            py: 1,
            pr: 0,
            flex: '1 1 auto',
            overflow: 'hidden',
            alignSelf: 'center',
          }}>

          <Box sx={{ display: 'flex', alignItems: 'center', color: isWatched ? 'text.secondary' : 'text.primary', mb: 0.5 }}>
            <ItemState isInWatchlist={isInWatchlist} watched={isWatched} rating={rating} />
            <Typography variant="body1" sx={{ fontStyle: title ? 'normal' : 'italic', color: isWatched ? 'text.secondary' : 'text.primary' }} noWrap>
              {title || 'Title missing'}
            </Typography>
          </Box>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {description}
          </Typography>

        </Box>
      </Box>

      {setDialogOpen &&
        <Box sx={{ display: 'flex', p: 2 }} onClick={ openDialog }>
          <MoreHoriz />
        </Box>
      }

    </Card>
  );
});
