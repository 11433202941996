import { FC, ReactElement, SyntheticEvent } from 'react';
import { Box, Card, Typography, useTheme } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';

import { useSession } from '../../hooks/auth';
import { useAnalyticsQueued } from '../../hooks/delicious-analytics';
import { useLoginPush } from '../../hooks/login-push';
import { FallbackImage } from '../FallbackImage';
import { Rating } from '../../generated/graphql';
import { ItemState } from '../ItemState';


interface TinyUnfurlProps {
  title?: string | null,
  image?: ReactElement | null,
  link?: string | null,
  isWatched: number,
  isInWatchlist: boolean,
  setDialogOpen: (o: boolean) => void,
  onClick?: (ev: SyntheticEvent) => void,
  rating?: Pick<Rating, 'rating'> | null,
}

export const TinyUnfurl: FC<TinyUnfurlProps> = function TinyUnfurl({ title, image, link, isWatched, isInWatchlist, setDialogOpen, onClick, rating }) {

  const theme = useTheme();
  const { user } = useSession();
  const { track } = useAnalyticsQueued();
  const { setLoginHint } = useLoginPush();

  const openDialog = (ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    if(user) {
      setDialogOpen(true);
      track('bookmark_button', { category: 'bookmark', origin: 'group' });
    } else {
      track('click_bookmark_post', { category: 'not-logged-in' });
      setLoginHint('You need to be logged in to save this.');
    }
  };

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', height: 36, borderRadius: 1, backgroundColor: 'grey.100' }} onClick={onClick}>
      <Box sx={{ width: 36, flex: '0 0 auto', height: '100%' }}>
        {image || (
          <FallbackImage color={theme.palette.primary.light} scale={2} style={{ height: '100%' }} />
        )}
      </Box>
      <Box
        sx={{
          px: 1,
          py: 1,
          flex: '1 1 auto',
          overflow: 'hidden',
          alignSelf: 'center',
          display: 'flex',
          alignItems: 'center',
          color: isWatched ? theme.palette.text.secondary : theme.palette.text.primary
        }}>

        <ItemState isInWatchlist={isInWatchlist} watched={isWatched} rating={rating} />
        <Typography variant="body1" color={isWatched ? 'textSecondary' : 'textPrimary'} noWrap>
          {title || link}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', p: 1 }} onClick={ openDialog }>
        <MoreHoriz />
      </Box>
    </Card>
  );
}
