import { useEffect, useCallback } from "react";
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { useTheme } from "@mui/material";


type SetParams = {
  color?: string,
  style?: 'Light' | 'Dark' | 'Default',
}


export function useStatusBarColor({ color, style }: SetParams) {

  const theme = useTheme();

  const set = useCallback(({ color: c, style: s }: SetParams) => {
    if (Capacitor.isNativePlatform() && c) {
      // eslint-disable-next-line react-compiler/react-compiler
      document.body.style.backgroundColor = c;
    }
    if (Capacitor.getPlatform() === 'ios' && c) {
      document.documentElement.style.setProperty('--status-bar-color', c);
    }
    if (Capacitor.getPlatform() === 'ios' && s) {
      StatusBar.setStyle({ style: Style[s] });
    }
  }, []);

  const reset = useCallback(() => {
    if (Capacitor.isNativePlatform()) {
      document.body.style.backgroundColor = theme.palette.background.paper;
    }
    if (Capacitor.getPlatform() === 'ios') {
      document.documentElement.style.setProperty('--status-bar-color', theme.palette.background.paper);
      StatusBar.setStyle({ style: Style.Light });
    }
  }, [theme]);

  useEffect(() => {
    set({ color, style });
    return reset;
  }, [ color, style, set, reset ]);

  return [ set, reset ];
}
