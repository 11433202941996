import PropTypes from 'prop-types';
import { useState } from 'react';
import { IconButton, Typography, useTheme } from '@mui/material';
import { Repeat } from 'react-feather';

import { useSession } from '../../hooks/auth';
import { useAnalyticsQueued } from '../../hooks/delicious-analytics';
import { useLoginPush } from '../../hooks/login-push';
import { MoreSheet } from './MoreSheet';


export function ReshareIconButton({ share, sx }) {

  const theme = useTheme();
  const { user } = useSession();
  const { track } = useAnalyticsQueued();
  const { setLoginHint } = useLoginPush();

  const [sheetOpen, setSheetOpen] = useState(false);

  const onClick = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    if(user) {
      setSheetOpen(true);
      track('reshare_button', { category: 'share', origin: 'post' });
    } else {
      track('click_share_post', { category: 'not-logged-in' });
      setLoginHint('You need to be logged in to share this.');
    }
  };

  const onClose = (ev) => {
    if(ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    setSheetOpen(false);
  };


  return (
    <>
      <IconButton
        aria-label="save"
        onClick={onClick}
        size="small"
        sx={{ height: 32, display: 'flex', flexDirection: 'row', p: 0, ...sx }}
      >
        <Repeat
          style={{
            stroke: theme.palette.grey[500],
            marginRight: 4,
            marginBottom: 1,
          }}
          width={14}
          height={14}
        />
        <Typography variant='body2'>
          Share
        </Typography>
      </IconButton>
      {sheetOpen &&
        <MoreSheet share={share} onClose={onClose} variant='share' />
      }
    </>
  );
}

ReshareIconButton.propTypes = {
  share: PropTypes.object,
  color: PropTypes.string,
  sx: PropTypes.object,
};
