import { FC, SyntheticEvent } from 'react';
import { IconButton, SxProps, Typography, useTheme } from '@mui/material';
import { Heart } from 'react-feather';

import { Contact, Share } from '../../generated/graphql';
import { useSession } from '../../hooks/auth';
import { useAnalyticsQueued } from '../../hooks/delicious-analytics';
import { useLoginPush } from '../../hooks/login-push';
import { AddReactionShare, useAddLike } from '../../hooks/reaction';


export type LikeButtonProps = {
  share: Pick<Share, '_id'> & {
    reactions?: Array<{
      reacted: Array<Pick<Contact, '_id'>>
    }>
  } & AddReactionShare,
  disabled?: boolean,
  sx?: SxProps
}


export const LikeButton: FC<LikeButtonProps> = function LikeButton({ share, disabled=false, sx={} }) {

  const theme = useTheme();
  const { user } = useSession();
  const { track } = useAnalyticsQueued();
  const { setLoginHint } = useLoginPush();
  const addLike = useAddLike();

  const handleClick = (selectedBefore: boolean) => (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if(user) {
      addLike(share, !selectedBefore);
      track('reaction', { category: 'feed', selected: selectedBefore, reacted: selectedBefore ? 1 : 0 });
      track('like_button', { category: 'share', origin: 'group' });
    } else {
      track('click_like_post', { category: 'not-logged-in' });
      setLoginHint('You need to be logged in to like this.');
    }
  }

  const reactions = share.reactions || [];
  const uniqueUsersReacted = new Set<string>();
  reactions.map(({ reacted }) => reacted.map(contact => uniqueUsersReacted.add(contact._id)));
  const userHasReacted = uniqueUsersReacted.has(user?._id);

  return (
    <>
      <IconButton
        onClick={handleClick(userHasReacted)}
        size="small"
        sx={{ height: 32, display: 'flex', flexDirection: 'row', p: 0, ...sx }}
        disabled={disabled}
      >
        <Heart size={15} color={ theme.palette.primary.main } fill={userHasReacted ? theme.palette.primary.main : 'none'} style={{ marginBottom: 1 }} />
        <Typography variant='body2' sx={{ ml: 0.5 }}>
          {userHasReacted ? 'Liked' : 'Like'}
        </Typography>
      </IconButton>
    </>
  );
}

export default LikeButton;
