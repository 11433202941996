import { FC, SyntheticEvent, useRef } from 'react';
import { useLocalStorage } from 'usehooks-ts'
import { Box, TextField, Portal, Paper, useTheme, PaletteColor } from '@mui/material';

import { Share, ShareCommentsFragmentFragment, useAddCommentMutation, useAddWatchStateCommentMutation } from '../generated/graphql';
import { useSession } from '../hooks/auth';
import { useAnalyticsQueued } from '../hooks/delicious-analytics';
import { useSuggestMentions } from '../hooks/use-suggest-mentions';
import { Button } from './Button';
import { Avatar } from './Avatar';


export type CommentInputProps = {
  comments: ShareCommentsFragmentFragment['comments'],
  share?: Pick<Share, '_id'>,
  watchStateId?: string,
  focusInput?: boolean,
  onBlur?: () => void,
  onFocus?: () => void,
  setCurrentWord: (word: string) => void,
  autocompleteWord: string | undefined,
  setAutocompleteWord: (word: string) => void,
  contextColor: PaletteColor,
  onClose: (e: SyntheticEvent) => void,
}


export const CommentInput: FC<CommentInputProps> = function CommentInput({ comments, share, watchStateId, focusInput=false, onBlur, onFocus, setCurrentWord, autocompleteWord, setAutocompleteWord, contextColor, onClose }) {
  const theme = useTheme();

  const [commentInput, setCommentInput] = useLocalStorage(`CommentInput.${share?._id}`, '');
  const inputRef = useRef();

  const { user } = useSession();
  const { track } = useAnalyticsQueued();

  useSuggestMentions(inputRef, commentInput, setCurrentWord, autocompleteWord, setAutocompleteWord, setCommentInput);

  const [addComment] = useAddCommentMutation();
  const [addWatchStateComment] = useAddWatchStateCommentMutation();

  const onSubmit = (ev: SyntheticEvent) => {
    ev.preventDefault();
    if(commentInput) {
      if(watchStateId) {
        addWatchStateComment({
          variables: {
            input: {
              watchStateId,
              text: commentInput,
            }
          },
        });
        track('add_comment', { category: 'comment', comment_length: commentInput.length, comment_on: 'watchState' });
        setCommentInput('');
        onBlur && onBlur();
      } else if(share) {
        const optimisticShare = {
          ...share,
          commentCount: comments.length + 1,
          comments: [
            ...comments,
            {
              _id: `temp-${Date.now()}`,
              __typename: 'Comment' as const,
              author: {
                _id: user._id,
                __typename: 'Contact' as const,
              },
              mentions: [],
              textLinks: [],
              reactions: [],
              text: commentInput,
              language: '',
              createdAt: (new Date()).toISOString(),
            }
          ]
        };
        addComment({
          variables: {
            input: {
              shareId: share._id,
              text: commentInput,
            }
          },
          optimisticResponse: {
            addComment: optimisticShare
          },
        });
        track('add_comment', { category: 'comment', comment_length: commentInput.length, comment_on: 'share' });
        setCommentInput('');
        onBlur && onBlur();
      }
    }
  }

  return (
    <Portal>
      <Paper
        onSubmit={onSubmit}
        key='input'
        component="form"
        sx={{
          borderRadius: 0,
          zIndex: theme.zIndex.modal + 1,
          position: 'fixed',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          width: '100%',
          backgroundColor: theme.palette.background.default,
          boxShadow: `0 -16px 20px -5px ${theme.palette.background.paper}`,
          pb: 'var(--sab)',
          borderTop: `1px solid ${theme.palette.grey['200']}`,
          maxWidth: `${theme.breakpoints.values.sm}${theme.breakpoints.unit}`,
        }}
        onClick={(ev: SyntheticEvent) => ev.stopPropagation() }
      >
        <Box sx={{
          padding: '8px 16px 16px 16px',
          display: 'flex',
          alignItems: 'center',
        }}>
          <Avatar
            sx={{ width: '36px', height: '36px' }}
            size='medium'
            contact={user}
          />
          <TextField
            fullWidth
            maxRows={4}
            multiline
            size='small'
            sx={{ ml: 1 }}
            placeholder='Add comment'
            value={commentInput}
            onChange={(ev) => setCommentInput(ev.target.value)}
            onKeyDown={(ev) => ev.key === 'Escape' && onClose(ev)}
            autoFocus={focusInput}
            inputRef={input => { inputRef.current = input; focusInput && input && setTimeout(() => input.focus(), 100) }}
            data-cy='comment-sheet-input'
            onBlur={() => !commentInput && onBlur && onBlur()}
            onFocus={() => onFocus && onFocus()}
          />
          <Button
            type='submit'
            size='small'
            variant='contained'
            shadowLevel={0}
            disabled={!commentInput}
            sx={{
              ml: 2,
              flex: '0 0 auto',
              minWidth: 'max-content',
              backgroundColor: contextColor.dark,
              color: contextColor.darkContrastText,
              '&:hover': {
                backgroundColor: contextColor.dark,
              }
            }}
            data-cy='comment-sheet-submit'
          >
            Post
          </Button>
        </Box>
      </Paper>
    </Portal>
  );
}
